import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  Box,
  Drawer,
  useTheme,
  IconButton,
  useMediaQuery,
  Breadcrumbs,
  Button,
  Typography,
} from "@mui/material";
import { MdClose, MdOutlineChevronLeft } from "react-icons/md";
import Logo from "../../../assets/images/logo/coinfident-favaicon.png";
import { agentsList } from "../../../data/agentsList";
import AgentHeader from "../../agents/agentHeader";
import AgentList from "../../agents/agentList";
import AgentInfo from "../../agents/agentInfo";
import AgentResponse from "../../agents/agentResponse";
import { convertAgentName } from "../../../utils/functions";
import { RootState } from "../../../features/store";
import {
  updateModalAgentList,
  setModalComponentsData,
  fetchAgentResponse,
} from "../../../features/agent/agentSlice";
import styles from "./agent.module.scss";

export default function Agent() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const info_ref = useRef<HTMLDivElement>(null);
  const agents_ref = useRef<HTMLDivElement>(null);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const agentState: any = useSelector((state: RootState) => state.agent);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("Influencers Explorer");
  const [showCompleteInfo, setShowCompleteInfo] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [isFinishedType, setFinishedType] = useState<any[]>([]);
  const [chartLoading, setChartLoading] = useState<boolean[]>([]);

  const clearDataHandler = () => {
    setSelectedAgent("Influencers Explorer");
    dispatch(
      setModalComponentsData({
        signal: null,
        top_influencers: null,
        tweets: null,
      })
    );

    dispatch(updateModalAgentList([]));
  };

  const selectedAgentItem = agentsList.find(
    (agent) => agent.agent_name === selectedAgent
  );

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsOpen(open);
    };

  const scrollToItem = (ref: any) => {
    if (downLg) {
      setTimeout(() => {
        ref?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 100);
    } else {
      info_ref.current?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  const error = `You've reached the maximum allowed requests for today. Wait for the limit to reset or upgrade your plan to avoid hitting the limit again.`;

  const fetchResponseHandler = async () => {
    const last_question =
      agentState.modalAgentList.length > 0 &&
      agentState.modalAgentList[agentState.modalAgentList.length - 1];

    const request_data = {
      query: last_question?.question,
      agent_type: convertAgentName(selectedAgent),
    };

    try {
      //@ts-ignore
      const res = await dispatch(fetchAgentResponse(request_data));

      if (res.payload.id) {
        return { ...res.payload };
      } else {
        toast.error("You've reached the maximum allowed requests for today.");
        return error;
      }
    } catch (error) {
      return {
        response_data: "An error occurred while fetching the agent response.",
      };
    }
  };

  useEffect(() => {
    if (agentState.modalAgentList.length > 0) {
      setTimeout(async () => {
        const lastNullIndex = agentState.modalAgentList
          .map((item: any) => item.answer)
          .lastIndexOf(null);

        const updatedChatList = await Promise.all(
          agentState.modalAgentList.map(async (item: any, index: number) => {
            if (index === lastNullIndex) {
              const answer = await fetchResponseHandler();

              return {
                ...item,
                answer: answer,
              };
            }
            return item;
          })
        );

        dispatch(updateModalAgentList(updatedChatList));
      }, 3);
    }
  }, [agentState.modalAgentList.length]);

  const agentBreadCrumb = () => {
    return (
      <Breadcrumbs
        className={styles.agentBreadCrumb}
        //@ts-ignore
        color={theme.palette.text.primary}
        sx={{ mb: downLg ? 0 : 3, mt: downLg ? 0 : 2 }}
      >
        <Typography
          variant="h6"
          style={{
            color: theme.palette.text.primary,
            cursor: "pointer",
            position: "relative",
            zIndex: 2,
          }}
          onClick={clearDataHandler}
        >
          Agents
        </Typography>
        <Typography
          color={(theme: any) => theme.palette.common.mute}
          variant="body2"
        >
          {agentState?.modalAgentSelectedModel}
        </Typography>
      </Breadcrumbs>
    );
  };

  return (
    <Box>
      <Box
        className={styles.fab_button}
        sx={{ background: theme.palette.common.white }}
      >
        <img src={Logo} onClick={toggleDrawer(true)} />
      </Box>

      <Drawer anchor="bottom" open={isOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: "100%",
            height: "90vh !important",
            background: (theme: any) => theme.palette.common.agentModalBg,
          }}
          className={styles.fab_menu}
          role="presentation"
        >
          <IconButton
            className={styles.fab_close_button}
            onClick={(event: any) => {
              toggleDrawer(false)(event);
              clearDataHandler();
            }}
            sx={{
              background: (theme: any) => theme.palette.common.agentModalBg,
            }}
          >
            <MdClose color={theme.palette.text.primary} />
          </IconButton>

          <Box
            className={`${styles.fab_menu_wrapper} customScrollbar ${
              theme.palette.mode === "light"
                ? "customScrollbar-light"
                : "customScrollbar-dark"
            }`}
          >
            <Box mb={8} mx={downLg ? 0.5 : 4}>
              {agentState.modalAgentList.length === 0 ? (
                <>
                  <AgentHeader isSmall />
                  <AgentList
                    isModal
                    agents_ref={agents_ref}
                    selectedAgentItem={selectedAgentItem}
                    setSelectedAgent={setSelectedAgent}
                    selectedAgent={selectedAgent}
                    scrollToItem={scrollToItem}
                    file={file}
                    setFile={setFile}
                    showCompleteInfo={showCompleteInfo}
                    setShowCompleteInfo={setShowCompleteInfo}
                  />

                  {!downLg && (
                    <Box ref={info_ref} className={styles.agentInfoWrapper}>
                      <AgentInfo
                        isModal
                        isLight
                        file={file}
                        setFile={setFile}
                        selectedAgent={selectedAgent}
                        selectedAgentItem={selectedAgentItem}
                        showCompleteInfo={showCompleteInfo}
                        setShowCompleteInfo={setShowCompleteInfo}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto !important",
                        padding: "4px",
                        mr: 2,
                      }}
                      onClick={clearDataHandler}
                    >
                      <MdOutlineChevronLeft size={downLg ? 20 : 25} />
                    </Button>
                    <Typography
                      variant="h3"
                      my={4}
                      align="center"
                      sx={{ fontSize: downLg ? "1.5rem" : "3rem" }}
                    >
                      <strong style={{ color: theme.palette.primary.main }}>
                        CAVA
                      </strong>
                    </Typography>
                  </Box>
                  {agentBreadCrumb()}
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.grey[700]}`,
                      borderRadius: "12px",
                      mt: 3,
                      p: 1,
                      pt: 2,
                    }}
                  >
                    <AgentResponse
                      isModal
                      agentType={agentState?.modalAgentSelectedModel}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
